import { NgModule } from '@angular/core';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainSharedModule } from '../main-shared/main-shared.module';

@NgModule({
  declarations: [FooterComponent, PageNotFoundComponent],
  imports: [HttpClientModule, HttpClientJsonpModule, MainSharedModule],
  exports: [FooterComponent],
})
export class CoreModule {}
