import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService, User } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private readonly auth: AuthService,
    @Inject(DOCUMENT) private dom: Document
  ) {}

  login(): void {
    this.auth.loginWithPopup();
  }

  login$(): Observable<void> {
    return this.auth.loginWithPopup();
  }

  signupWithRedirectToStore(): void {
    this.auth.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
      appState: { target: '/store' },
    });
  }

  signupWithRedirect(): void {
    this.auth.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  }

  logout(): void {
    this.auth.logout({ logoutParams: { returnTo: this.dom.location.origin } });
  }

  isLoggedIn(): Observable<boolean> {
    return this.auth.isAuthenticated$;
  }

  getUser(): Observable<User | null | undefined> {
    return this.auth.user$;
  }
}
