<mat-card>
  <a [routerLink]="['/products', product?.id]">
    <img
      [ngSrc]="cardImage"
      crossorigin="anonymous"
      alt="image du produit"
      width="100"
      height="125"
      mat-card-image
    />
  </a>
  <mat-card-content>
    <mat-card-title
      ><a [routerLink]="['/products', product?.id]">{{
        product?.name
      }}</a></mat-card-title
    >
  </mat-card-content>
  <mat-card-footer>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <p *ngIf="product?.price !== 0; else free" class="priceText">
        {{ product?.price! | price | currency }}
      </p>
      <ng-template #free>
        <p class="priceText">Gratuit</p>
      </ng-template>
      <ng-container *ngIf="product">
        <button
          (click)="addToCart(product)"
          class="mat-elevation-z0"
          color="primary"
          mat-mini-fab
          aria-label="ajouter au panier"
        >
          <mat-icon class="mat-icon-lg mat-icon material-icons"
            >shopping_cart
          </mat-icon>
        </button>
      </ng-container>
    </div>
  </mat-card-footer>
</mat-card>
