import { Pipe, PipeTransform } from '@angular/core';
import { dinero, toUnit } from 'dinero.js';
import { CAD } from '@dinero.js/currencies';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(value: number): number {
    // TODO merge with Angular currency pipe and adapt currency to logged in user Country and anonymous user's locale
    return toUnit(
      dinero({
        amount: value,
        currency: CAD,
      })
    );
  }
}
