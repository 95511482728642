import { Component, Input, OnInit } from '@angular/core';
import { CartItem } from '../../core/cart-item';
import { ProductService } from '../../product/product.service';
import { CartService } from '../../core/cart.service';

@Component({
  selector: 'app-cart-row',
  templateUrl: './cart-row.component.html',
  styleUrls: ['./cart-row.component.scss'],
})
export class CartRowComponent implements OnInit {
  @Input() item: CartItem | undefined;
  itemImage = '';

  constructor(
    private readonly cartService: CartService,
    private readonly productService: ProductService
  ) {}

  ngOnInit(): void {
    this.itemImage = this.setProductImage();
  }

  public removeItem(item: CartItem | undefined): void {
    if (item) {
      this.cartService.removeFromCart(item);
    }
  }

  public setProductImage(): string {
    const imagesUrls = this.productService.getProductImagesUrlsCached(
      this.item?.product!
    );
    let image = '';
    if (imagesUrls.length > 0) {
      image = imagesUrls[0];
    }
    return image;
  }
}
