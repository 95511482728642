import { DEFAULT_CURRENCY_CODE, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { CartItem } from './cart-item';
import { map, reduce } from 'rxjs/operators';
import { Product } from '../product/product';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { add, dinero, multiply, toSnapshot, toUnit } from 'dinero.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { CAD } from '@dinero.js/currencies';

export interface Item {
  item_name: string;
  price: number;
  currency: string;
  quantity: number;
}

@Injectable({
  providedIn: 'root',
})
export class CartService {
  products: CartItem[] =
    JSON.parse(localStorage.getItem('cartItem') as string) || [];
  cartItems: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.products);

  constructor(
    public snackBar: MatSnackBar,
    @Inject(DEFAULT_CURRENCY_CODE) public currencyCode: string,
    protected readonly googleAnalytics: GoogleAnalyticsService
  ) {}

  getItems(): Observable<CartItem[]> {
    return this.cartItems.asObservable();
  }

  removeFromCart(item: CartItem): void {
    this.products = this.products.filter(
      (product) => product.product.id !== item.product.id
    );
    this.saveCartToLocalStorage();
    this.emitRemovedCartEvent(item.product);
  }

  emptyCart(): void {
    this.products = [];
    this.saveCartToLocalStorage();
  }

  getTotalAmount(): Observable<number> {
    return from(this.products).pipe(
      map(
        (item) =>
          toSnapshot(
            multiply(
              dinero({ amount: item.product.price, currency: CAD }),
              item.quantity
            )
          ).amount
      ),
      reduce(
        (totalAmount, productPriceForQuantity: number) =>
          toSnapshot(
            add(
              dinero({ amount: totalAmount, currency: CAD }),
              dinero({ amount: productPriceForQuantity, currency: CAD })
            )
          ).amount,
        0
      )
    );
  }

  addToCart(product: Product, quantity: number): void {
    if (quantity <= 0) return;
    const productIsPresentInCart = this.products.find((items, index) => {
      if (items.product.id === product.id) {
        const qty = this.products[index].quantity + quantity;
        if (qty > 1) {
          // this.products[index].quantity = qty;
          this.snackBarAlreadyPresent(product);
        }
        return true;
      }
      return false;
    });

    if (!productIsPresentInCart) {
      const item = { product, quantity };
      this.products.push(item);
      this.emitAddToCartEvent(product);
      this.snackBarSuccess(product);
    }

    this.saveCartToLocalStorage();
  }

  updateCartQuantity(product: Product, quantity: number): CartItem | undefined {
    return this.products.find((items, index) => {
      if (items.product.id === product.id) {
        const qty = this.products[index].quantity + quantity;
        if (qty > 0) this.products[index].quantity = qty;
        this.saveCartToLocalStorage();
        return true;
      }
      return false;
    });
  }

  trackByCartItemID(index: number, cartItem: CartItem): string {
    return cartItem?.product?.id!;
  }

  cartProductsToGAItems(): Item[] {
    return this.products.map((cartItem) => ({
      item_name: cartItem.product.name,
      price: toUnit(
        dinero({
          amount: cartItem.product.price,
          currency: CAD,
        })
      ),
      currency: this.currencyCode,
      quantity: 1,
    }));
  }

  emitViewCartEvent(): void {
    this.getTotalAmount().subscribe((totalAmount) => {
      this.googleAnalytics.gtag('event', 'view_cart', {
        currency: this.currencyCode,
        items: this.cartProductsToGAItems(),
        value: toUnit(
          dinero({
            amount: totalAmount,
            currency: CAD,
          })
        ),
      });
    });
  }

  private emitAddToCartEvent(product: Product): void {
    const value: number = toUnit(
      dinero({
        amount: product.price,
        currency: CAD,
      })
    );
    this.googleAnalytics.gtag('event', 'add_to_cart', {
      currency: this.currencyCode,
      items: [
        {
          item_name: product.name,
          price: toUnit(
            dinero({
              amount: product.price,
              currency: CAD,
            })
          ),
          currency: this.currencyCode,
          quantity: 1,
        },
      ],
      value: value,
    });
  }

  private emitRemovedCartEvent(product: Product): void {
    this.googleAnalytics.gtag('event', 'remove_from_cart', {
      currency: this.currencyCode,
      items: [
        {
          item_name: product.name,
          price: toUnit(
            dinero({
              amount: product.price,
              currency: CAD,
            })
          ),
          currency: this.currencyCode,
          quantit: 1,
        },
      ],
      value: toUnit(
        dinero({
          amount: product.price,
          currency: CAD,
        })
      ),
    });
  }

  private snackBarSuccess(product: Product): void {
    const message = `${product.name} à été ajouté à votre panier.`;
    this.snackBar.open(message, '×', {
      verticalPosition: 'top',
      duration: 3000,
    });
  }

  private snackBarAlreadyPresent(product: Product): void {
    const message = `${product.name} est déjà dans votre panier.`;
    this.snackBar.open(message, '×', {
      verticalPosition: 'top',
      duration: 3000,
    });
  }

  private saveCartToLocalStorage(): void {
    localStorage.setItem('cartItem', JSON.stringify(this.products));
    this.cartItems.next(this.products);
  }
}
