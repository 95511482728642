<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutAlign.lt-md="space-around center"
  fxLayoutGap="16px"
  fxLayoutGap.lt-md="8px"
>
  <div>
    <img crossorigin="anonymous" width="50" height="40" [ngSrc]="itemImage" alt="" class="image-preview" />
  </div>
  <div>
    <div class="primaryText">
      {{ item?.product?.name }}
    </div>
    <div *ngIf="item?.product?.price !== 0; else free" class="accentText">
      {{ item?.product?.price! | price | currency }}
    </div>
    <ng-template #free>
      <div class="accentText">Gratuit</div>
    </ng-template>
  </div>

  <div>
    <button
      (click)="$event.stopPropagation(); removeItem(item)"
      color="accent"
      mat-icon-button
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
