import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Product } from '../../product/product';
import { Observable } from 'rxjs';
import { ProductService } from '../../product/product.service';
import { map, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { CanonicalService } from '../../core/canonical.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  products$: Observable<Product[]> | undefined;
  productsPage = 1;
  total = 0;
  newProducts$: Observable<Product[]> | undefined;
  newProductsPage = 1;
  newTotal = 0;

  constructor(
    readonly productService: ProductService,
    private title: Title,
    private readonly canonicalService: CanonicalService
  ) {}

  ngOnInit(): void {
    this.getNewPageProducts(1);
    this.getPageProducts(1);
    this.canonicalService.setCanonicalURL('https://www.creasources.ca/');
  }

  getNewPageProducts(page: number): void {
    this.newProducts$ = this.productService.getAllPaged(page, 8).pipe(
      tap((res) => {
        this.newProductsPage = page;
        this.newTotal = res.count;
      }),
      map((res) => res.products)
    );
  }

  getPageProducts(page: number): void {
    this.products$ = this.productService.getDiscoveryPaged(page, 4).pipe(
      tap((res) => {
        this.productsPage = page;
        this.total = res.count;
      }),
      map((res) => res.products)
    );
  }
}
