<div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column">
  <div fxFlex="60">
    <h2 class="primaryBoldText">Infolettre</h2>
    <p class="primaryText">
      Inscrivez-vous à notre infolettre pour avoir des nouvelles en primeur et
      pour stimuler votre créativité!
    </p>
  </div>
  <form (ngSubmit)="submit()" fxFlex="40" fxLayoutGap="16px">
    <mat-form-field color="primary">
      <mat-label class="primaryBoldText">Votre courriel</mat-label>
      <input [formControl]="emailControl" matInput type="email" />
      <input
        [hidden]="true"
        name="b_f381c685af645ae950dcaf5c5_5480a41feb"
        tabindex="-1"
        type="text"
        value=""
      />
      <mat-error *ngIf="emailControl.hasError('required')">
        L'Adresse courriel est <strong>requise</strong>
      </mat-error>
    </mat-form-field>

    <button color="primary" mat-raised-button type="submit">S'ABONNER</button>
    <mat-error *ngIf="error">
      Une erreur est survenue, veuillez réessayer
    </mat-error>
    <p *ngIf="submitted" class="success">
      Merci, vous êtes maintenant abonné(e)! Un courriel de confirmation vous a
      été envoyé.
    </p>
  </form>
</div>
