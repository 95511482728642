<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button
      (click)="sidenav.toggle()"
      fxHide.gt-sm
      fxShow="true"
      mat-icon-button
      aria-label="menu"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <a
      [routerLink]="['']"
      gaEvent="home"
      mat-button
      routerLinkActive="router-link-active"
    >
      <img
        crossorigin="anonymous"
        alt="Créasources"
        height="26"
        width="200"
        ngSrc="assets/images/CREASOURCES_Logo_Horizontal_FondBleu_FR.svg"
      /></a>

    <span fxFlex></span>
    <div fxHide.lt-md="true" fxShow="true">
      <a
        gaEvent="about"
        href="https://blog.creasources.ca/about/"
        mat-button
        routerLinkActive="router-link-active"
        rel="noopener"
        target="_blank"
      >{{ about.display }}</a
      >
      <a
        gaEvent="faq"
        [routerLink]="[faq.route]"
        mat-button
        routerLinkActive="router-link-active"
      >{{ faq.display }}</a
      >
      <a
        mat-button
        [routerLink]="['products']"
        routerLinkActive="router-link-active"
      >Produits</a
      >
      <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async, user: user$ | async} as data">
        <button
          *ngIf="data.isLoggedIn; else loginBlock"
          [matMenuTriggerFor]="accountMenu"
          [routerLink]=""
          mat-button
          type="button"
          routerLinkActive="router-link-active"
        >
          <mat-icon>person</mat-icon>
          {{ myAccount }}
          <mat-icon class="mat-icon-sm caret cur-icon"
          >arrow_drop_down
          </mat-icon>
        </button>
        <ng-template #loginBlock>
          <button
            gaEvent="login"
            (click)="login()"
            mat-button
            type="button"
            routerLinkActive="router-link-active"
          >
            <mat-icon>person</mat-icon>
            {{ connect }}
          </button>
        </ng-template>
        <mat-menu #accountMenu="matMenu">
          <button mat-menu-item [routerLink]="['purchases']">Mes Achats</button>
          <button
            [routerLink]="[myStore.route]"
            gaEvent="my_store"
            mat-menu-item
          >
            {{ myStore.display }}
          </button>
          <button (click)="logout()" mat-menu-item>{{ disconnect }}</button>
        </mat-menu>
      </ng-container>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar>
  <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async } as data">
    <mat-toolbar-row>
      <form (ngSubmit)="onSearch()" [formGroup]="searchForm">
        <mat-form-field color="primary" floatLabel="never">
          <mat-label>Rechercher</mat-label>
          <input
            type="search"
            placeholder="Rechercher"
            data-test="searchInput"
            formControlName="search"
            matInput
          />
          <button
            aria-label="rechercher"
            color="primary"
            mat-button
            mat-icon-button
            matSuffix
          >
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </form>
      <span fxFlex></span>

      <a
        fxHide.lt-sm="true"
        gaEvent="signup"
        color="primary"
        *ngIf="!data.isLoggedIn"
        [routerLink]="'/signup'"
        mat-button
        routerLinkActive="router-link-active"
      >S'inscrire</a
      >
      <app-shopping-widgets></app-shopping-widgets>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="!data.isLoggedIn" fxHide.gt-xs="true">
      <span fxFlex></span>

      <a
        gaEvent="signup"
        color="primary"
        [routerLink]="'/signup'"
        mat-button
        routerLinkActive="router-link-active"
      >S'inscrire</a
      >
    </mat-toolbar-row>
  </ng-container>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <a
        gaEvent="about"
        href="https://blog.creasources.ca/about/"
        mat-list-item
        routerLinkActive="router-link-active"
        rel="noopener"
        target="_blank"
      >{{ about.display }}</a
      >
      <a
        gaEvent="faq"
        [routerLink]="[faq.route]"
        mat-list-item
        routerLinkActive="router-link-active"
      >{{ faq.display }}</a
      >
      <a
        [routerLink]="['products']"
        mat-list-item
        routerLinkActive="router-link-active"
      >Produits</a
      >
      <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async } as data">
        <mat-expansion-panel
          *ngIf="data.isLoggedIn; else loginBlock"
          [class.mat-elevation-z0]="true"
          dense
        >
          <mat-expansion-panel-header>
            {{ myAccount }}
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a [routerLink]="['purchases']" mat-list-item>Mes Achats</a>
            <a
              mat-list-item
              [routerLink]="[myStore.route]"
              gaEvent="my_store"
            >{{ myStore.display }}</a
            >
            <button type="button" mat-list-item (click)="logout()">
              {{ disconnect }}
            </button>
          </mat-nav-list>
        </mat-expansion-panel>
        <ng-template #loginBlock>
          <button
            gaEvent="login"
            (click)="login()"
            mat-list-item
            type="button"
            routerLinkActive="router-link-active"
          >
            <mat-icon>person</mat-icon>
            {{ connect }}
          </button>
        </ng-template>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content id="main-content">
    <div style="overflow-x: hidden">
      <div style="min-height: 100vh">
        <router-outlet (activate)="resetPosition()"></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
