import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseInterface } from './base-interface';

export abstract class BaseService<T extends BaseInterface> {
  apiUrl = `${environment.API_URL}`;

  protected readonly HTTP_OPTIONS = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(protected readonly httpClient: HttpClient) {}

  getAllPublic(): Observable<T[]> {
    return this.httpClient.get<T[]>(this.apiUrl, this.HTTP_OPTIONS);
  }

  getAllProtected(): Observable<T[]> {
    return this.httpClient.get<T[]>(this.apiUrl, this.HTTP_OPTIONS);
  }

  getOneByIdPublic(id: string): Observable<T> {
    const url = `${this.apiUrl}/${id}`;
    return this.httpClient.get<T>(url, this.HTTP_OPTIONS);
  }

  getOneByIdProtected(id: string): Observable<T> {
    const url = `${this.apiUrl}/${id}`;
    return this.httpClient.get<T>(url, this.HTTP_OPTIONS);
  }

  addOne(toAdd: T): Observable<T> {
    return this.httpClient.post<T>(this.apiUrl, toAdd, this.HTTP_OPTIONS);
  }

  updateOne(toUpdate: T): Observable<T> {
    const url = `${this.apiUrl}/${toUpdate.id}`;
    return this.httpClient.put<T>(url, toUpdate, this.HTTP_OPTIONS);
  }
}
