export const environment = {
  production: true,
  API_URL: 'https://api.creasources.ca/api/v1/',
  baseURL: 'https://www.creasources.ca',
  backendURL: 'https://leprojet-prod-62zntfklzq-uk.a.run.app',
  imageURL: 'https://cdn.creasources.ca/api/v1',
  clientID: 'YZU77TmOClaB8vSiBBvjCG0xhM3AjN6f',
  authDomain: 'creasources.us.auth0.com',
  cacheLocation: 'localstorage',
  ga: 'G-M8X5KDFYBN',
  fb: '154054659562446',
};
