import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CartService } from '../../core/cart.service';
import { ProductService } from '../../product/product.service';
import { Product } from '../../product/product';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent implements OnInit {
  @Input() product: Product | undefined;
  cardImage = 'assets/images/no-product.svg';

  constructor(
    private readonly cartService: CartService,
    private readonly productService: ProductService
  ) {}

  ngOnInit(): void {
    this.setProductImage();
  }

  private setProductImage(): void {
    const imagesUrls = this.productService.getProductImagesUrlsCached(
      this.product!
    );
    if (imagesUrls.length > 0) {
      this.cardImage = imagesUrls[0];
    }
  }

  addToCart(product: Product, quantity = 1): void {
    this.cartService.addToCart(product, quantity);
  }
}
