import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { AppComponent } from './app.component';
import { loadStripe } from '@stripe/stripe-js/pure';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/fr-CA';
import '@angular/common/locales/global/en-CA';
import {
  AuthHttpInterceptor,
  AuthModule,
  HttpMethod,
} from '@auth0/auth0-angular';
import { CacheLocation } from '@auth0/auth0-spa-js';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { LocaleService } from './core/locale.service';
import { AppModule } from './app.module';
import { APP_BASE_HREF } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

function appLoadCurrency(localeService: LocaleService) {
  return () => localeService.getCurrencyCached().toPromise();
}

@NgModule({
  imports: [
    AppModule,
    AuthModule.forRoot({
      domain: environment.authDomain,
      clientId: environment.clientID,
      authorizationParams: {
        audience: 'https://creasources.ca/api',
        redirect_uri: window.location.origin,
      },
      httpInterceptor: {
        allowedList: [
          `${environment.API_URL}product_payloads`,
          `${environment.API_URL}product_payloads/*`,
          `${environment.API_URL}purchases`,
          `${environment.API_URL}purchases/*`,
          `${environment.API_URL}onboarding/*`,
          `${environment.API_URL}subscriptions/*`,
          `${environment.API_URL}stores/store/user/products`,
          `${environment.API_URL}stores/store/user`,
          `${environment.API_URL}stores/dashboard`,
          `${environment.API_URL}payment/checkout`,
          {
            uri: `${environment.API_URL}stores/*`,
            httpMethod: HttpMethod.Post,
          },
          {
            uri: `${environment.API_URL}stores`,
            httpMethod: HttpMethod.Post,
          },
          {
            uri: `${environment.API_URL}stores/*`,
            httpMethod: HttpMethod.Put,
          },
          {
            uri: `${environment.API_URL}products/*`,
            httpMethod: HttpMethod.Delete,
          },
        ],
      },
      cacheLocation: environment.cacheLocation as CacheLocation,
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.ga, [
      {
        command: 'config',
        values: [environment.ga],
      },
    ]),
    NgxGoogleAnalyticsRouterModule,
    ServiceWorkerModule.register(
      `${location.protocol}//${location.host}/ngsw-worker.js`,
      {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }
    ),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: `${location.protocol}//${location.host}/`,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: loadStripe, useValue: loadStripe },
    {
      provide: LOCALE_ID,
      useValue: navigator.language,
    },
    {
      provide: APP_INITIALIZER,
      deps: [LocaleService],
      useFactory: appLoadCurrency,
      multi: true,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      deps: [LocaleService],
      useFactory: (localeService: LocaleService) => {
        return localeService.currencyCode;
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
