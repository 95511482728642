import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent {
  submitted = false;
  mailChimpEndpoint =
    'https://creasources.us2.list-manage.com/subscribe/post-json?u=f381c685af645ae950dcaf5c5&amp;id=5480a41feb&';
  error = '';
  // reactive form components
  emailControl = new UntypedFormControl('', [Validators.required, Validators.email]);

  constructor(private http: HttpClient) {}

  submit() {
    this.error = '';
    if (this.emailControl.status === 'VALID') {
      const params = new HttpParams()
        .set('EMAIL', this.emailControl.value)
        .set('b_f381c685af645ae950dcaf5c5_5480a41feb', ''); // hidden input name

      const mailChimpUrl = this.mailChimpEndpoint + params.toString();

      // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
      this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(
        (response) => {
          if (response.result && response.result !== 'error') {
            this.submitted = true;
            this.emailControl.reset();
            this.emailControl.clearValidators();
            this.emailControl.updateValueAndValidity();
          } else {
            this.error = response.msg;
          }
        },
        (error) => {
          console.error(error);
          this.error = 'Sorry, an error occurred.';
        }
      );
    }
  }
}
