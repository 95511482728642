import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';

export interface LocalizedCurrency {
  currency: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  apiUrl = `${environment.API_URL}`;
  protected publicHttpClient: HttpClient;
  protected readonly HTTP_OPTIONS = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  private currency = '';
  private defaultCurrency: LocalizedCurrency = {
    currency: 'CAD',
  };

  private currencyCached$: Observable<LocalizedCurrency> | undefined;

  constructor(protected readonly handler: HttpBackend) {
    this.publicHttpClient = new HttpClient(handler);
  }

  get currencyCode(): string {
    return this.currency;
  }

  getCurrencyCached(): Observable<LocalizedCurrency> {
    if (!this.currencyCached$) {
      this.currencyCached$ = this.getCurrency().pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      );
    }
    return this.currencyCached$;
  }

  private getCurrency(): Observable<LocalizedCurrency> {
    return this.publicHttpClient
      .get<LocalizedCurrency>(this.apiUrl + 'geo/currency', this.HTTP_OPTIONS)
      .pipe(
        catchError(() => of(this.defaultCurrency)),
        tap((currency) => (this.currency = currency.currency))
      );
  }
}
