import { Component, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { map, take } from 'rxjs/operators';
import { Category } from './category';
import { CategoryService } from './category.service';

@Component({
  selector: 'app-categories-display',
  templateUrl: './categories-display.component.html',
  styleUrls: ['./categories-display.component.scss'],
})
export class CategoriesDisplayComponent implements OnInit {
  treeControl: NestedTreeControl<Category>;
  dataSource: MatTreeNestedDataSource<Category>;

  constructor(private readonly categoryService: CategoryService) {
    this.treeControl = new NestedTreeControl<Category>((node) => node.children);
    this.dataSource = new MatTreeNestedDataSource<Category>();
  }

  ngOnInit(): void {
    this.categoryService
      .getAllCached()
      .pipe(
        map((categories) => (this.dataSource.data = categories)),
        take(1)
      )
      .subscribe();
  }

  hasChild = (_: number, node: Category) =>
    node.children && node.children.length > 0;
}
