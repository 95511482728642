import {
  BrowserModule,
  HammerModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { APP_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/fr-CA';
import '@angular/common/locales/global/en-CA';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { MainSharedModule } from './main-shared/main-shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UniversalExternalRewriteInterceptor } from './core/universal-external-rewrite.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from './shared/custom-paginator-intl/custom-paginator.intl';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    HammerModule,
    MainSharedModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    { provide: APP_ID, useValue: 'serverApp' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniversalExternalRewriteInterceptor,
      multi: true,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true } },
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
