import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from './category';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BaseService } from '../../core/base.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends BaseService<Category> {
  private allCache$: Observable<Category[]> | undefined;

  constructor(protected override readonly httpClient: HttpClient) {
    super(httpClient);
    this.apiUrl += 'categories';
  }

  getAllCached(): Observable<Category[]> {
    if (!this.allCache$) {
      this.allCache$ = this.getAllPublic().pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      );
    }
    return this.allCache$;
  }

  getCategoryByIDFromCache(
    categories: Category[],
    categoryID: string
  ): Category | undefined {
    for (let index = 0; index < categories.length; index++) {
      const element = categories[index];
      if (element.id === categoryID) {
        return element;
      } else {
        if (element.children) {
          const found = this.getCategoryByIDFromCache(
            element.children,
            categoryID
          );

          if (found) {
            return found;
          }
        }
      }
    }
    return undefined;
  }
}
