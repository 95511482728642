import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CartItem } from '../../core/cart-item';
import { CartService } from '../../core/cart.service';
import { Observable } from 'rxjs';
import { CheckoutService } from '../../core/checkout.service';

@Component({
  selector: 'app-shopping-widgets',
  templateUrl: './shopping-widgets.component.html',
  styleUrls: ['./shopping-widgets.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShoppingWidgetsComponent implements OnInit {
  shoppingCartItems$: Observable<CartItem[]> | undefined;

  constructor(
    readonly cartService: CartService,
    private readonly checkoutService: CheckoutService
  ) {}

  ngOnInit(): void {
    this.shoppingCartItems$ = this.cartService.getItems();
  }

  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  public checkout(): void {
    this.checkoutService.checkoutCart().subscribe();
  }

  public emitViewCartEvent(): void {
    this.cartService.emitViewCartEvent();
  }
}
