import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable()
export class UniversalExternalRewriteInterceptor implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId: never) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (isPlatformServer(this.platformId)) {
      const httpsReq = request.clone({
        url: request.url.replace(environment.baseURL, environment.backendURL),
      });
      return next.handle(httpsReq);
    }
    return next.handle(request);
  }
}
