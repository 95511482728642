<h3 id="categoryList">Catégories</h3>
<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="category-tree mat-elevation-z2"
  id="category-tree"
>
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodeToggle
  >
    <a [queryParams]="{ category: node.id }" [routerLink]="'/products'">{{
      node.name
      }}</a>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div class="mat-tree-node">
      <button
        type="button"
        data-test="categoryExpandButton"
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      {{ node.name }}
    </div>
    <div [class.category-tree-invisible]="!treeControl.isExpanded(node)"
         role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
