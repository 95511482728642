import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { HomeComponent } from './home/home/home.component';

const routes: Routes = [
  {
    path: 'store',
    loadChildren: () =>
      import('./store/store.module').then((m) => m.StoreModule),
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./product/product.module').then((m) => m.ProductModule),
  },
  {
    path: '',
    component: HomeComponent,
    title: 'Accueil',
    data: { revalidate: 900 },
  },
  {
    path: 'purchases',
    loadChildren: () =>
      import('./purchase/purchase.module').then((m) => m.PurchaseModule),
    title: 'Mes Achats',
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup/signup.module').then((m) => m.SignupModule),
    title: 'Inscription',
  },
  {
    path: 'success',
    redirectTo: 'purchases',
    pathMatch: 'full',
  },
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full',
    title: 'Accueil',
  },
  {
    path: 'login',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'conditions',
    loadChildren: () =>
      import('./conditions/conditions.module').then((m) => m.ConditionsModule),
    title: `Conditions d'utilisation`,
    data: { revalidate: 1800 },
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./privacy/privacy.module').then((m) => m.PrivacyModule),
    title: 'Politique sur la vie privée',
    data: { revalidate: 1800 },
  },
  {
    path: 'copyright',
    loadChildren: () =>
      import('./copyright/copyright.module').then((m) => m.CopyrightModule),
    title: `Politique sur les droits d'auteurs`,
    data: { revalidate: 1800 },
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
    title: 'Foire aux questions',
    data: { revalidate: 1800 },
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then((m) => m.CartModule),
    title: 'Mon Panier',
  },
  {
    path: 'cancel',
    loadChildren: () => import('./cart/cart.module').then((m) => m.CartModule),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
