import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Product } from './product';
import { BaseService } from '../core/base.service';
import { Observable } from 'rxjs';
import { PagedProducts } from './paged-products';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseService<Product> {
  constructor(protected override readonly httpClient: HttpClient) {
    super(httpClient);
    this.apiUrl += 'products';
  }

  trackByProductID(index: number, product: Product): string {
    return product?.id!;
  }

  getImageUrl(productId: string, imageName: string): string {
    return `${environment.imageURL}/products/${productId}/image/${imageName}`;
  }

  getProductImagesUrlsCacheBreaking(product: Product): string[] {
    if (product?.images_location && product.images_location.length > 0) {
      const timestamp = new Date().getTime();
      const imageUrls: string[] = [];
      product.images_location.forEach((value) =>
        imageUrls.push(`${this.getImageUrl(product?.id!, value)}?${timestamp}`)
      );
      return imageUrls;
    }
    return [];
  }

  getProductImagesUrlsCached(product: Product): string[] {
    if (product?.images_location && product.images_location.length > 0) {
      const imageUrls: string[] = [];
      product.images_location.forEach((value) =>
        imageUrls.push(`${this.getImageUrl(product?.id!, value)}`)
      );
      return imageUrls;
    }
    return ['assets/images/no-product.svg'];
  }

  delete(productId: string): Observable<unknown> {
    const url = `${this.apiUrl}/${productId}`;
    return this.httpClient.delete(url, this.HTTP_OPTIONS);
  }

  search(query: string): Observable<Product[]> {
    const url = `${this.apiUrl}/search`;
    let params = new HttpParams();
    params = params.append('query', String(query));
    return this.httpClient.get<Product[]>(url, {
      headers: this.HTTP_OPTIONS.headers,
      params: params,
    });
  }

  getDiscoveryPaged(page: number, size: number): Observable<PagedProducts> {
    let params = new HttpParams();
    params = params.append('page', String(page));
    params = params.append('size', String(size));
    return this.httpClient.get<PagedProducts>(`${this.apiUrl}/discovery`, {
      headers: this.HTTP_OPTIONS.headers,
      params: params,
    });
  }

  getAllPaged(page: number, size: number): Observable<PagedProducts> {
    let params = new HttpParams();
    params = params.append('page', String(page));
    params = params.append('size', String(size));
    return this.httpClient.get<PagedProducts>(this.apiUrl, {
      headers: this.HTTP_OPTIONS.headers,
      params: params,
    });
  }

  getAllPagedCategory(
    categoryID: string,
    page: number,
    size: number
  ): Observable<PagedProducts> {
    let params = new HttpParams();
    params = params.append('category', categoryID);
    params = params.append('page', String(page));
    params = params.append('size', String(size));
    return this.httpClient.get<PagedProducts>(this.apiUrl, {
      headers: this.HTTP_OPTIONS.headers,
      params: params,
    });
  }

  getAllPagedStore(
    storeID: string,
    page: number,
    size: number
  ): Observable<PagedProducts> {
    let params = new HttpParams();
    params = params.append('store', storeID);
    params = params.append('page', String(page));
    params = params.append('size', String(size));
    return this.httpClient.get<PagedProducts>(this.apiUrl, {
      headers: this.HTTP_OPTIONS.headers,
      params: params,
    });
  }
}
