import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from, Observable } from 'rxjs';
import { CheckoutRequest, CheckoutResponse } from './checkout';
import { CartService } from './cart.service';
import { map, mergeMap, take } from 'rxjs/operators';
import { StripeError } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { dinero, toUnit } from 'dinero.js';
import { CAD } from '@dinero.js/currencies';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  apiUrl = `${environment.API_URL}`;

  protected readonly HTTP_OPTIONS = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private readonly authenticationService: AuthenticationService,
    protected readonly googleAnalytics: GoogleAnalyticsService,
    private readonly cartService: CartService,
    protected readonly protectedHttpClient: HttpClient,
    private readonly router: Router,
    protected readonly handler: HttpBackend
  ) {
    this.apiUrl += 'payment';
  }

  checkoutCart(): Observable<boolean | void | Promise<{ error: StripeError }>> {
    const checkout$ = this.checkout();

    const isLoggedIn$ = this.authenticationService.isLoggedIn();

    return isLoggedIn$.pipe(
      mergeMap((isLoggedIn) =>
        isLoggedIn ? checkout$ : this.authenticationService.login$()
      ),
      take(1)
    );
  }

  private checkout(): Observable<
    boolean | undefined | Promise<{ error: StripeError }>
  > {
    const checkoutRequest$ = this.emitBeginCheckout().pipe(
      mergeMap(() => this.cartService.getItems()),
      map((items) => items.map((item) => item.product.id!)),
      mergeMap((productIds) => this.checkoutRequest({ products: productIds }))
    );

    return checkoutRequest$.pipe(
      mergeMap((checkoutResponse) =>
        this.isOnlyFreeProducts(checkoutResponse)
          ? this.router.navigate(['/purchases'])
          : this.redirectToStripe(checkoutResponse)
      )
    );
  }

  private isOnlyFreeProducts(checkoutResponse: CheckoutResponse): boolean {
    return checkoutResponse.session_id === '';
  }

  private checkoutRequest(
    request: CheckoutRequest
  ): Observable<CheckoutResponse> {
    const url = `${this.apiUrl}/checkout`;
    return this.protectedHttpClient.post<CheckoutResponse>(
      url,
      request,
      this.HTTP_OPTIONS
    );
  }

  private redirectToStripe(
    checkout: CheckoutResponse
  ): Observable<Promise<{ error: StripeError }> | undefined> {
    return from(loadStripe(checkout.publishable_key)).pipe(
      map((stripe) =>
        stripe?.redirectToCheckout({ sessionId: checkout.session_id })
      )
    );
  }

  private emitBeginCheckout(): Observable<void> {
    return this.cartService
      .getTotalAmount()
      .pipe(map((totalAmount) => this.beginCheckout(totalAmount)));
  }

  private beginCheckout(totalAmount: number): void {
    const value: number = toUnit(
      dinero({
        amount: totalAmount,
        currency: CAD,
      })
    );
    this.googleAnalytics.gtag('event', 'begin_checkout', {
      currency: this.cartService.currencyCode,
      items: this.cartService.cartProductsToGAItems(),
      value: value,
    });
    const ids: string[] = this.cartService.products.map(
      (product) => product.product?.id!
    );
  }
}
