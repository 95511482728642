<div fxLayout="row" fxLayoutAlign="start center" id="banner">
  <div
    class="container"
    fxLayout="row"
    fxFlex
    fxLayout.xs="column"
    fxLayoutGap="2rem"
    fxLayoutAlign="start center"
  >
    <!--main image-->
    <!--content-->
    <div>
      <br /><br /><br />
      <br /><br /><br />
      <h1 class="bannerText">Bienvenue sur Créasources</h1>
      <p class="bannerText">
        Partagez vos outils et ressources en intervention!
      </p>
      <button
        type="button"
        [routerLink]="'/signup'"
        color="white"
        mat-stroked-button
      >
        Ouvrir ma boutique
      </button>
      <br /><br /><br />
    </div>
  </div>
</div>
<div class="container">
  <div
    gdAreas.sm="categories | bestSellers | new"
    gdAreas="categories bestSellers | new new "
    gdRows="auto auto"
    gdColumns="1fr 3fr"
    gdAreas.xs="categories | bestSellers | new"
    gdColumns.sm="repeat(auto-fill, minmax(400px, 2fr))"
    gdColumns.xs="repeat(auto-fill, minmax(300px, 1fr))"
    gdGap="16px"
    gdRows.sm="auto auto auto"
    gdRows.xs="auto auto auto"
  >
    <div gdArea="categories">
      <app-categories-display></app-categories-display>
    </div>
    <div gdArea="bestSellers">
      <h2 class="accentHeader">Nouveautés</h2>
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div
          *ngFor="
            let product of (newProducts$ | async)!
              | paginate
                : {
                    id: 'new',
                    itemsPerPage: 8,
                    currentPage: newProductsPage,
                    totalItems: newTotal
                  };
            trackBy: productService.trackByProductID
          "
          fxFlex.gt-sm="25"
          fxFlex.sm="50"
          fxFlex.xs="50"
        >
          <app-product-card [product]="product"></app-product-card>
        </div>
      </div>
      <br />
      <pagination-template
        #newPaginator="paginationApi"
        (pageChange)="getNewPageProducts($event)"
        [id]="'new'"
      >
        <div fxLayout="row" fxLayoutAlign="space-evenly center">
          <button
            (click)="newPaginator.previous()"
            [disabled]="newPaginator.isFirstPage()"
            aria-label="précédent"
            color="primary"
            mat-icon-button
          >
            <mat-icon>arrow_back_ios</mat-icon>
          </button>
          <button
            (click)="newPaginator.next()"
            [disabled]="newPaginator.isLastPage()"
            aria-label="suivant"
            color="primary"
            mat-icon-button
          >
            <mat-icon>arrow_forward_ios</mat-icon>
          </button>
        </div>
      </pagination-template>
    </div>
    <div gdArea="new">
      <h2 class="accentHeader">À découvrir</h2>
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div
          *ngFor="
            let product of (products$ | async)!
              | paginate
                : {
                    id: 'discover',
                    itemsPerPage: 4,
                    currentPage: productsPage,
                    totalItems: total
                  };
            trackBy: productService.trackByProductID
          "
          fxFlex.gt-sm="25"
          fxFlex.sm="50"
          fxFlex.xs="50"
        >
          <app-product-card [product]="product"></app-product-card>
        </div>
      </div>
      <br />
      <pagination-template
        #discoverPaginator="paginationApi"
        (pageChange)="getPageProducts($event)"
        [id]="'discover'"
      >
        <div fxLayout="row" fxLayoutAlign="space-evenly center">
          <button
            (click)="discoverPaginator.previous()"
            [disabled]="discoverPaginator.isFirstPage()"
            aria-label="précédent"
            color="primary"
            mat-icon-button
          >
            <mat-icon>arrow_back_ios</mat-icon>
          </button>
          <button
            (click)="discoverPaginator.next()"
            [disabled]="discoverPaginator.isLastPage()"
            aria-label="suivant"
            color="primary"
            mat-icon-button
          >
            <mat-icon>arrow_forward_ios</mat-icon>
          </button>
        </div>
      </pagination-template>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="center center" id="lowbanner">
  <div
    class="container"
    fxLayout="row"
    fxFlex
    fxLayout.xs="column"
    fxLayoutGap="2rem"
    fxLayoutAlign="start center"
  >
    <!--main image-->
    <!--content-->
    <div>
      <br /><br /><br />
      <br /><br /><br />
      <h2 class="bannerText">Qui sommes-nous?</h2>
      <p class="bannerText">
        Créasources est une plateforme d’échange d’outils pour les intervenants
        de la relation d’aide. Elle vous permet de trouver, de donner ou de
        vendre des outils d’intervention pour toutes les clientèles et toutes
        les problématiques. C’est une bonne occasion de gagner un revenu
        supplémentaire grâce à vos créations et de dénicher de merveilleux
        outils !
      </p>
      <p class="bannerText">
        Notre mission: faciliter le partage d’outil d’intervention, valoriser la
        créativité des intervenants et faciliter votre quotidien grâce à la
        banque de matériel offert par la communauté.
      </p>
      <p class="bannerText">
        Pour avoir des astuces, des ressources et connecter encore plus avec
        nous, consultez notre blogue!
      </p>
      <a
        mat-stroked-button
        color="white"
        href="https://blog.creasources.ca/"
        rel="noopener"
        target="_blank"
        >ALLER SUR NOTRE BLOGUE</a
      >
      <br /><br /><br />
    </div>
  </div>
</div>
<div class="container">
  <app-newsletter></app-newsletter>
</div>
