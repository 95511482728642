<ng-container *ngIf="shoppingCartItems$ | async as shoppingCartItems">
  <button
    fxHide.lt-sm="true"
    (click)="emitViewCartEvent()"
    [matMenuTriggerFor]="menu"
    mat-button
    color="primary"
  >
    <mat-icon>local_grocery_store</mat-icon>
    <span *ngIf="shoppingCartItems.length > 0" class="cart-count-wrapper">{{
      shoppingCartItems.length
    }}</span>
    Mon Panier
  </button>
  <button
    aria-label="panier"
    fxHide.gt-xs="true"
    (click)="emitViewCartEvent()"
    [matMenuTriggerFor]="menu"
    mat-button
    color="primary"
  >
    <mat-icon>local_grocery_store</mat-icon>
    <span *ngIf="shoppingCartItems.length > 0" class="cart-count-wrapper">{{
      shoppingCartItems.length
    }}</span>
  </button>
  <mat-menu #menu="matMenu" class="cart-preview">
    <br />
    <p *ngIf="!shoppingCartItems.length" class="menu-item">
      Votre panier est vide
    </p>

    <ng-container
      *ngFor="
        let item of shoppingCartItems;
        trackBy: cartService.trackByCartItemID
      "
    >
      <div class="menu-item">
        <app-cart-row [item]="item"></app-cart-row>
      </div>
      <br />
      <mat-divider></mat-divider>
      <br />
    </ng-container>
    <div class="primaryText" fxLayout="row" fxLayoutAlign="space-around center">
      <b>Sous-total:</b>
      <b>{{ (getTotal() | async)! | price | currency }}</b>
    </div>
    <br />
    <mat-divider></mat-divider>
    <br />
    <div fxLayout="row" fxLayoutAlign="space-evenly center">
      <button
        [routerLink]="'/cart'"
        color="primary"
        mat-raised-button
        type="button"
      >
        Voir Panier
      </button>
      <button
        (click)="checkout()"
        *ngIf="shoppingCartItems.length"
        color="accent"
        mat-raised-button
        type="button"
      >
        Passer à la Caisse
      </button>
    </div>
    <br />
  </mat-menu>
</ng-container>
