import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToolbarItem } from './core/toolbar-item';
import { AuthenticationService } from './core/authentication.service';
import { Router } from '@angular/router';
import { CanonicalService } from './core/canonical.service';
import { DOCUMENT } from '@angular/common';
import { User } from '@auth0/auth0-angular';
import { tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoggedIn$: Observable<boolean> | undefined;
  user$: Observable<User | null | undefined> | undefined;
  searchForm: UntypedFormGroup = this.formBuilder.group({
    search: [''],
  });
  about: ToolbarItem = {
    display: 'À Propos',
    route: '',
  };
  faq: ToolbarItem = {
    display: 'FAQ/Aide',
    route: 'faq',
  };
  myStore: ToolbarItem = {
    display: 'Ma Boutique',
    route: 'store',
  };
  connect = 'Se Connecter';
  disconnect = 'Se Déconnecter';
  myAccount = 'Mon Compte';

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly canonicalService: CanonicalService,
    protected readonly googleAnalytics: GoogleAnalyticsService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.isLoggedIn$ = this.authenticationService.isLoggedIn();
    this.user$ = this.authenticationService.getUser();
    this.canonicalService.setCanonicalURL('https://www.creasources.ca/');
  }

  login(): void {
    this.authenticationService.login();
  }

  logout(): void {
    this.authenticationService.logout();
  }

  resetPosition(): void {
    const myDiv = this.document.getElementById('main-content');
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }

  onSearch(): void {
    if (!this.searchForm.valid) {
      return;
    }
    this.router.navigate(['products/search'], {
      queryParams: { query: this.searchForm.get('search')?.value as string },
    });
  }
}
