<footer class="footer">
  <div
    class="container"
    fxLayout="row wrap"
    fxLayoutAlign="space-between start"
  >
    <div
      fxFlex="100"
      fxFlex.gt-sm="30"
      fxFlex.sm="50"
      class="footer-widget about-widget"
    >
      <a gaEvent="home" [routerLink]="['']"
        ><img
          crossorigin="anonymous"
          alt="footer logo"
          width="300"
          height="39"
          ngSrc="assets/images/CREASOURCES_Logo_Horizontal_FondBlanc_FR.svg"
      /></a>
      <p>
        Créasources est une plateforme de partage et de vente de matériel
        d'intervention psychosocial.
      </p>
      <div class="link"></div>
    </div>

    <div
      fxFlex="100"
      fxFlex.gt-sm="23"
      fxFlex.sm="50"
      ngClass.xs="mt-2"
      class="footer-widget links-widget links-widget-pac"
    ></div>

    <div
      fxFlex="100"
      fxFlex.gt-sm="23"
      fxFlex.sm="50"
      ngClass.xs="mt-2"
      class="footer-widget subscribe-widget"
    >
      <div class="title">
        <h5>Information</h5>
      </div>
      <div class="row">
        <ul>
          <li>
            <a
              gaEvent="blog"
              href="https://blog.creasources.ca/"
              rel="noopener"
              target="_blank"
              >Blog</a
            >
          </li>
          <li><a [routerLink]="['faq']" gaEvent="faq">FAQ</a></li>
          <li>
            <a [routerLink]="['conditions']" gaEvent="conditions"
              >Conditions d'Utilisation</a
            >
          </li>
          <li>
            <a [routerLink]="['privacy']" gaEvent="privacy"
              >Politique sur la Vie Privée</a
            >
          </li>
          <li>
            <a [routerLink]="['copyright']" gaEvent="copyright"
              >Politique sur les Droits d'Auteurs</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div
      fxFlex="100"
      fxFlex.gt-sm="23"
      fxFlex.sm="50"
      ngClass.sm="mt-2"
      ngClass.xs="mt-2"
      class="footer-widget subscribe-widget"
    >
      <div class="title">
        <h5>Contactez Nous</h5>
      </div>
      <div fxLayout="row" class="tel-box">
        <mat-icon> email</mat-icon>
        <div class="text">
          <p>
            <a gaEvent="contactEmail" href="mailto:info@creasources.ca"
              >info@creasources.ca</a
            >
          </p>
        </div>
      </div>

      <ul fxLayout="row" class="social list-inline">
        <li>
          <a
            gaEvent="facebookLink"
            mat-icon-button
            href="https://www.facebook.com/Creasources.ca"
            rel="noopener"
            target="_blank"
            aria-label="facebook"
          >
            <mat-icon>facebook</mat-icon>
          </a>
        </li>
        <li>
          <a
            gaEvent="pinterestLink"
            href="https://www.pinterest.ca/PlateformeCreasources/"
            mat-icon-button
            rel="noopener"
            target="_blank"
          >
            <img
              alt="pinterest link"
              width="24"
              height="24"
              ngSrc="assets/images/icons8-pinterest-24.webp"
            />
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>
