import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private dom: Document) {}

  setCanonicalURL(url?: string): void {
    const oldLink: HTMLLinkElement | null = this.dom.querySelector(
      'link[rel=canonical]'
    );
    if (oldLink) {
      oldLink.remove();
    }
    const canURL =
      url == undefined
        ? environment.baseURL +
          this.dom.location.pathname +
          this.dom.location.search
        : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }
}
